import React, { Component } from 'react';
import './Header.css';


export default class Project extends Component {


    render() {

        return (

            <header className="Header" role="banner">

                <hgroup>

                    <h1>Alexander Fukui</h1>

                    <h2>Web Designer and Developer</h2>

                </hgroup>

            </header>

        )

    }

}